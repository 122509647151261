import React from "react";
import Typewriter from "typewriter-effect";


const Usermessage = ({children="waos", user=null, avatar=null}) => {
    return (
        <div className='messageuser d-flex flex-row'>
            <div className='message__content_user'>
                <div className='message__content__header'>
                    <h5 className='message__content__header__user'>{user === null ? "Usuario" : user}</h5>
                </div>
                <div className='message__content__body'>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.typeString(children)
                            .start()
                            }} options={{cursor: "", delay:1}}

                    />
                </div>
            </div>
            <div className='message__avatar'>
                {avatar === null ? <img className="rounded avatar" src='https://i.imgur.com/6VBx3io.png' alt='avatar' /> : <img className="rounded avatar" src={avatar} alt='avatar' />}
            </div>

        </div>
    );
}
// te dejo el nombre en el codigo de esta 
// aplicacion para que sea parte de el => {Leti}

const GPTmessage = ({children="No hay respuesta.", nombre="", endf}) => {
    let iconLink = nombre === "Rias Gremory" ? "https://rule34.xxx/thumbnails/7520/thumbnail_79fc253f416c0bbeb5b7e8526a160be6.jpg?8578915" : "https://i.imgur.com/6VBx3io.png"
    // https://rule34.xxx/thumbnails/7520/thumbnail_79fc253f416c0bbeb5b7e8526a160be6.jpg?8578915
    // https://i.imgur.com/6VBx3io.png
    return (
        <div className='messagegpt d-flex flex-row'>
            <div className='message__avatar'>
                <img className="rounded avatar" src={iconLink} alt='avatar' />
            </div>
            <div className='message__content'>
                <div className='message__content__header'>
                    <h6 className='message__content__header__user'>{nombre === "" ? "GPT" : nombre}</h6>
                </div>
                <div className='message__content__body'>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.typeString(children)
                            .callFunction(() => {
                                endf()
                            })
                            .start()
                            }} options={{cursor: "", delay:10}}

                    />

                </div>
            </div>

        </div>
    );
}


// parte de codigo hecho. to => {Sady}

// fntn version 2.3 - Log: Simplified typewriter effect.
const Titulo = ({children="Chat__ 2.1.0"}) => {
    return (
        <Typewriter
            onInit={(typewriter) => {
                typewriter
                    .typeString(children)
                    .start();
            }}
            options={{ cursor: "", delay: 20 }}
        />
    );

}
export {Usermessage, GPTmessage, Titulo};
