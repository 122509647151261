import React, { useState, useEffect, useRef } from "react";
import "./App.css";
//import Fecha from './components/fecha';
import { Usermessage, GPTmessage, Titulo } from "./components/messages";

const urlServer = 'https://chatjgomezc145apiserver.azurewebsites.net'

function App() {
    let avlin = "https://unavatar.io/jgomezc145";
    const [chatName, setChatName] = useState("GPT-3.5");
    const [mensaje, setMensaje] = useState("");
    const [msgEspera, setMsgEspera] = useState("");
    const [mensajes, setMensajes] = useState([
        { content: "Hola, ¿en que puedo ayudarte?", role: "assistant" },
    ]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [titled, setTitled] = useState(false);
    const [tokens, setTokens] = useState(0);
    const [chatGPT, setChatGPT] = useState(true);

    const messagesContainerRef = useRef(null);

    useEffect(() => {
        // Esta función se ejecutará cada vez que el estado 'mensajes' se actualice.
        // Moveremos el scroll hacia abajo (hacia el último mensaje) cada vez que se agregue un nuevo mensaje.
        if (messagesContainerRef.current) {
            const messagesContainer = messagesContainerRef.current;
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }

        // puedo detectar height del mensaje y hacer que el scroll se mueva en base a eso
    }, [mensajes]);

    // Si la variable chatGPT cambia, chatName cambia a "Rias Gremory" si false, o "GPT-3.5" si true
    useEffect(() => {
        if (chatGPT) {
            setChatName("GPT-3.5");
        } else {
            setChatName("Rias Gremory");
        }
    }, [chatGPT]);

    async function sendMessage(e) {
        // Se envia un mensaje por fetch a [urlServer]/chat/[mensaje]
        // Se recibe un mensaje por fetch a http://
        e.preventDefault();
        // añade el mensaje a la lista de mensajes
        setMensajes([...mensajes, { content: mensaje, role: "user" }]);

        let waos = mensaje;
        setMensaje("");

        await fetch(`${urlServer}chat/${waos}`, { "no-cors": true })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // como puedo añadir data.response.text a mensajes sin que se borre el mensaje anterior?

                //cambia \n por <br>

                let msg = data.response.text.replace(/\n/g, "<br>");
                setMsgEspera({ content: msg, role: "assistant" });

                // extrae el largo del mensaje
                //maxcount = msg.length;
            });
    }

    async function endh() {
        if (messagesContainerRef.current) {
            const messagesContainer = messagesContainerRef.current;
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }

    const handleCheckboxChange = (event) => {
        setChatGPT(event.target.checked); // Actualiza el estado "chat" con el valor del checkbox
    };

    async function sendConversation(e) {
        e.preventDefault();

        const fecha = new Date();

        // Obtener la hora y los minutos
        const horas = fecha.getHours();
        const minutos = fecha.getMinutes();

        // Formatear la hora y los minutos en el formato deseado (asegurándose de agregar ceros a la izquierda si es necesario)
        const horaFormateada =
            (horas < 10 ? "0" : "") +
            horas +
            ":" +
            (minutos < 10 ? "0" : "") +
            minutos;

        const newMessage = {
            content:
                mensaje +
                `<span style="display:none">++${horaFormateada}++</span>`,
            role: "user",
        };
        setMensaje("");
        setMensajes([...mensajes, newMessage]);

        // Crea un nuevo array con los mensajes, cada item es un objeto {content: mensaje, role: from}
        let tosend = [...mensajes, newMessage].map((mensaje) => {
            return { content: mensaje.content, role: mensaje.role };
        });

        setLoading(true);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json", // Especificamos que el cuerpo es un objeto JSON
            },
            body: JSON.stringify(tosend), // Convertimos el objeto a una cadena JSON para incluirlo en el cuerpo de la solicitud
        };

        // Realizamos la solicitud POST con fetch
        //fetch('http://127.0.0.1:3333/conversationwRias', requestOptions)
        const apiUrl = `${urlServer}/conversation`;

        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                //console.log('Respuesta del servidor:', data);
                if (data.response.responseType === "Imagen") {
                    setMsgEspera({
                        content: `${data.response.openai} <br><img src="${data.response.data[0].image}" alt="Imagen" width="512" height="512"/>`,
                        role: "assistant",
                    });
                    setLoading(false);
                    setTokens(tokens + data.response.tokens);
                    if (!titled) {
                        setTitle("Imaginacion creada");
                        setTitled(true);
                    }
                    return;
                }
                let msg = data.response.text.replace(/\n/g, "<br>");
                setMsgEspera({ content: msg, role: "assistant" });
                setLoading(false);
                setTokens(tokens + data.response.tokens);
                if (!titled) {
                    setTitle(mensajes[1].content.toUpperCase());
                    setTitled(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setMsgEspera({
                    content:
                        "Ha ocurrido un error comuncandose al servidor. Lo lamento no puedo responder tu mensaje. Info para el desarrollador: " + error + ".",
                    role: "assistant",
                });
                console.error("Error al realizar la solicitud:", error);
            });

        // redondea 0.012321313123 a 3 decimales
        //console.log(Math.round(tokens*(0.09/1000) * 1000) / 1000);
    }

    async function sendConversationRias(e) {
        e.preventDefault();

        const fecha = new Date();

        // Obtener la hora y los minutos
        const horas = fecha.getHours();
        const minutos = fecha.getMinutes();

        // Formatear la hora y los minutos en el formato deseado (asegurándose de agregar ceros a la izquierda si es necesario)
        const horaFormateada =
            (horas < 10 ? "0" : "") +
            horas +
            ":" +
            (minutos < 10 ? "0" : "") +
            minutos;

        const newMessage = {
            content:
                mensaje +
                `<span style="display:none">++${horaFormateada}++</span>`,
            role: "user",
        };
        setMensaje("");
        setMensajes([...mensajes, newMessage]);

        // Crea un nuevo array con los mensajes, cada item es un objeto {content: mensaje, role: from}
        let tosend = [...mensajes, newMessage].map((mensaje) => {
            return { content: mensaje.content, role: mensaje.role };
        });

        setLoading(true);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json", // Especificamos que el cuerpo es un objeto JSON
            },
            body: JSON.stringify(tosend), // Convertimos el objeto a una cadena JSON para incluirlo en el cuerpo de la solicitud
        };

        // Realizamos la solicitud POST con fetch
        //fetch('http://127.0.0.1:3333/conversationwRias', requestOptions)
        const apiUrl = `${urlServer}/conversationwRias`;

        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                //console.log('Respuesta del servidor:', data);
                if (data.response.responseType === "Imagen") {
                    setMsgEspera({
                        content: `${data.response.openai} <br><img src="${data.response.data[0].image}" alt="Imagen" width="512" height="512"/>`,
                        role: "assistant",
                    });
                    setLoading(false);
                    setTokens(tokens + data.response.tokens);
                    if (!titled) {
                        setTitle("Imaginacion creada");
                        setTitled(true);
                    }
                    return;
                }
                let msg = data.response.text.replace(/\n/g, "<br>");
                setMsgEspera({ content: msg, role: "assistant" });
                setLoading(false);
                setTokens(tokens + data.response.tokens);
                if (!titled) {
                    setTitle(mensajes[1].content.toUpperCase());
                    setTitled(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setMsgEspera({
                    content:
                        "Lo siento cariño, pero no he podido conectarme con el servidor~",
                    role: "assistant",
                });

                console.error("Error al realizar la solicitud:", error);
            });

        // redondea 0.012321313123 a 3 decimales
        //console.log(Math.round(tokens*(0.09/1000) * 1000) / 1000);
    }

    function loadConver(e) {
        e.preventDefault();
        let camber = prompt("Ingrese el MSArray");
        let conver = "";

        // si se cancela el prompt, no hacer nada

        if (camber === "" || camber === null) {
            return;
        } else {
            setLoading(true);
            conver = JSON.parse(camber);

            setMensajes(conver);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (msgEspera !== "") {
            setMensajes([...mensajes, msgEspera]);
            setMsgEspera("");
        }
    }, [msgEspera, mensajes]);

    return (
        <>
            <div className="container">
                {mensajes.length <= 1 ? (
                    <div className="confige centrao">
                        <h2 className="">Hola de nuevo</h2>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                checked={chatGPT}
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                            />
                            <label
                                className="form-check-label"
                                for="flexSwitchCheckChecked"
                            >
                                Hablar con {chatName}
                            </label>
                        </div>
                        <span
                            style={
                                chatGPT
                                    ? { display: "none" }
                                    : { display: "block" }
                            }
                        >
                        F    Se utlizará un modelo entrenado con conversaciones
                            de Rias Gremory para generar las respuestas. Usalo
                            bajo tu propio riesgo.
                        </span>
                        <br />
                        <button
                            type="button"
                            onClick={(e) => loadConver(e)}
                            className="btn btn-info "
                        >
                            Cargar MSArray
                        </button>
                    </div>
                ) : (
                    <div></div>
                )}
                <div className="titleh">
                    <h3>
                        {" "}
                        {title ? <Titulo>{title}</Titulo> : "Chat 2.1.0"}{" "}
                        {loading ? (
                            <span className="loading">Loading...</span>
                        ) : (
                            <span></span>
                        )}
                    </h3>
                </div>
                <div
                    className="container messages d-flex flex-column"
                    ref={messagesContainerRef}
                >
                    {mensajes.map((mensaje, index) => {
                        return mensaje.role === "user" ? (
                            <Usermessage
                                user={"JGomezC145"}
                                avatar={avlin}
                                key={index}
                            >
                                {mensaje.content}
                            </Usermessage>
                        ) : (
                            <GPTmessage
                                endf={endh}
                                nombre={chatName}
                                key={index}
                            >
                                {mensaje.content}
                            </GPTmessage>
                        );
                    })}
                </div>
                <form
                    onSubmit={(e) => {
                        chatGPT ? sendConversation(e) : sendConversationRias(e);
                    }}
                    className="input-group sendmsg mb-3"
                >
                    <input
                        type="text"
                        value={mensaje}
                        onChange={(e) => {
                            setMensaje(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Type a message..."
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                    />
                    <input
                        type="submit"
                        className="btn btn-outline-secondary"
                        id="button-addon2"
                        value="Enviar"
                    />
                    {tokens ? (
                        <span className="badge bg-warning">
                            Tokens usados: {tokens} <br></br> Coste: $
                            {Math.round(tokens * (0.09 / 1000) * 1000) / 1000}{" "}
                        </span>
                    ) : (
                        <span className="badge bg-warning">--</span>
                    )}
                    {/* <button
                        className="btn btn-outline-info"
                        onClick={(e) => loadConver(e)}
                    >
                        Cargar MSArray
                    </button> */}
                </form>
            </div>
        </>
    );
}

export default App;
